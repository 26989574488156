@import url(https://fonts.googleapis.com/css?family=Amatic+SC:regular,bold&subset=latin,latin-ext);


body {
  text-align: center;
}

.title {
  font-size: 80px;
  line-height: 0.9;
  margin: 30px 0 0 0;
  padding: 0 30px;
}

.intro-img {
  display: block;
  margin: 0 auto;
  width: 88%;
}

.author {
  font-size: 40px;
  line-height: 1;
  margin: 20px 0 10px 0;
  padding: 0 30px;
}

.illustrator {
  font-size: 24px;
  margin: 0;
}

.illustrator a {
  color: inherit;
}

.sidekick {
  display: block;
  margin: 90px auto 0 auto;
  width: 150px;
}

.hero {
  display: block;
  width: 100%;
}

.copy {
  display: block;
  font-size: 28px;
  margin: 0;
  padding: 0 50px 23px 50px;
  width: 100%;
}

.copy span {
  white-space: nowrap;
}

.lang-buttons {
  display: inline-block;
  padding: 12px 0 90px 0;
  position: relative;
}

.lang-buttons::after {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  height: 1px;
  margin-left: -30px;
  left: 50%;
  position: absolute;
  width: 60px;
  top: 0;
}

.lang-buttons span {
  display: inline-block;
  font-size: 20px;
  opacity: 0.4;
  transition: opacity 500ms ease-in-out;
  padding: 8px;
  margin: 0 1px;
}

.lang-buttons span.is-active {
  opacity: 1;
}

.end {
  display: flex;
  flex-direction: column;
}

.end-text {
  font-size: 70px;
  line-height: 1;
  padding: 0 30px;
  margin: 100px 0 0 0;
}

.end-img {
  display: block;
  margin: 30px auto 0 auto;
  width: 200px;
}

.replay {
  display: flex;
  flex-direction: column;
  font-size: 32px;
  margin: 40px 0 200px 0;
}

.replay img {
  height: 25px;
  margin-top: 5px;
}

.desktop {
  overflow: hidden;
}

a {
  color: inherit;
}


/* CONTAINER ---------------------------------------------------------------- */

.container-d {
  display: flex;
  height: 100vh;
  opacity: 0;
  position: absolute;
  transition: opacity 1s ease-in-out;
  width: 100vw;
}

.container-d.is-active {
  opacity: 1;
  z-index: 1;
}

.container-d:nth-child(2) {
  transition: opacity 1s ease-in-out 0s;
}

.container-d:nth-child(2).is-active {
  transition: opacity 1s ease-in-out 1s;
}

.inner-left-d {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 60px 30px 50px 30px;
  width: 100%;
}

.copy-d {
  background: #fff;
  font-size: 30px;
  text-align: center;
  max-width: 470px;
  margin: 0;
  width: 100%;
}

.copy-d span {
  white-space: nowrap;
}

.lang-buttons-d {
  display: flex;
  margin-top: auto;
  padding-bottom: 10px;
}

.lang-buttons-d span {
  cursor: pointer;
  display: block;
  font-size: 21px;
  padding: 5px;
  opacity: 0.4;
  margin: 0 2px;
  transition: opacity 0.5s ease-in-out;
}

.lang-buttons-d span:hover {
  opacity: 0.55;
}

.lang-buttons-d span.is-active {
  opacity: 1;
}

.sidekick-d {
  max-width: 200px;
  width: 100%;
}

.hero-d {
  height: 100%;
}


/* INTRO -------------------------------------------------------------------- */

.container-d.cover {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
}

.title-d {
  font-size: 80px;
  margin: 0 0 auto 0;
}


.intro-img-d {
  display: block;
  margin: 0 auto;
  max-width: 330px;
  width: 100%;
}

@media screen and (max-height: 655px) {
  .intro-img-d {
    max-width: 250px;
  }
}

@media screen and (max-height: 565px) {
  .intro-img-d {
    max-width: 200px;
  }
}

.intro-controls-d {
  display: flex;
  margin: 20px auto 0 auto;
}

.intro-controls-d h5 {
  font-size: 24px;
  margin: 0;
  padding: 0 20px;
}

.intro-controls-d img {
  -webkit-animation: bounceLeft 3s infinite;
          animation: bounceLeft 3s infinite;
  display: block;
  width: 40px;
}

.intro-controls-d img:last-of-type {
  -webkit-animation: bounceRight 0.5s linear 0s infinite;
          animation: bounceRight 0.5s linear 0s infinite;
}

@-webkit-keyframes bounceRight {
  from {
    transform: translateX(0);
  }

  75% {
    transform: translateX(5px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes bounceRight {
  from {
    transform: translateX(0);
  }

  75% {
    transform: translateX(5px);
  }

  to {
    transform: translateX(0);
  }
}

.intro-controls-d span {
  display: block;
  width: 100%;
}

.arrow-left-d, .arrow-right-d {

}

.arrow-left-d img, .arrow-right-d img {
  display: block;
  height: 20px;
}

.author-d {
  font-size: 40px;
  margin: auto 0 0 0;
}

.illustrator-d {
  font-size: 22px;
  margin: 10px 0 0 0;
}


/* END ---------------------------------------------------------------------- */

.container-d.end {
  transition: opacity 1s ease-in-out 0s;
}

.container-d.is-active.end {
  transition: opacity 1s ease-in-out 1s;
}

.container-d .replay {
  cursor: pointer;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: 'Amatic SC';
  font-weight: 700;
  margin: 0;
}

