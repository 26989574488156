html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: 'Amatic SC';
  font-weight: 700;
  margin: 0;
}
