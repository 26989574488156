.desktop {
  overflow: hidden;
}

a {
  color: inherit;
}


/* CONTAINER ---------------------------------------------------------------- */

.container-d {
  display: flex;
  height: 100vh;
  opacity: 0;
  position: absolute;
  transition: opacity 1s ease-in-out;
  width: 100vw;
}

.container-d.is-active {
  opacity: 1;
  z-index: 1;
}

.container-d:nth-child(2) {
  transition: opacity 1s ease-in-out 0s;
}

.container-d:nth-child(2).is-active {
  transition: opacity 1s ease-in-out 1s;
}

.inner-left-d {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 60px 30px 50px 30px;
  width: 100%;
}

.copy-d {
  background: #fff;
  font-size: 30px;
  text-align: center;
  max-width: 470px;
  margin: 0;
  width: 100%;
}

.copy-d span {
  white-space: nowrap;
}

.lang-buttons-d {
  display: flex;
  margin-top: auto;
  padding-bottom: 10px;
}

.lang-buttons-d span {
  cursor: pointer;
  display: block;
  font-size: 21px;
  padding: 5px;
  opacity: 0.4;
  margin: 0 2px;
  transition: opacity 0.5s ease-in-out;
}

.lang-buttons-d span:hover {
  opacity: 0.55;
}

.lang-buttons-d span.is-active {
  opacity: 1;
}

.sidekick-d {
  max-width: 200px;
  width: 100%;
}

.hero-d {
  height: 100%;
}


/* INTRO -------------------------------------------------------------------- */

.container-d.cover {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
}

.title-d {
  font-size: 80px;
  margin: 0 0 auto 0;
}


.intro-img-d {
  display: block;
  margin: 0 auto;
  max-width: 330px;
  width: 100%;
}

@media screen and (max-height: 655px) {
  .intro-img-d {
    max-width: 250px;
  }
}

@media screen and (max-height: 565px) {
  .intro-img-d {
    max-width: 200px;
  }
}

.intro-controls-d {
  display: flex;
  margin: 20px auto 0 auto;
}

.intro-controls-d h5 {
  font-size: 24px;
  margin: 0;
  padding: 0 20px;
}

.intro-controls-d img {
  animation: bounceLeft 3s infinite;
  display: block;
  width: 40px;
}

.intro-controls-d img:last-of-type {
  animation: bounceRight 0.5s linear 0s infinite;
}

@keyframes bounceRight {
  from {
    transform: translateX(0);
  }

  75% {
    transform: translateX(5px);
  }

  to {
    transform: translateX(0);
  }
}

.intro-controls-d span {
  display: block;
  width: 100%;
}

.arrow-left-d, .arrow-right-d {

}

.arrow-left-d img, .arrow-right-d img {
  display: block;
  height: 20px;
}

.author-d {
  font-size: 40px;
  margin: auto 0 0 0;
}

.illustrator-d {
  font-size: 22px;
  margin: 10px 0 0 0;
}


/* END ---------------------------------------------------------------------- */

.container-d.end {
  transition: opacity 1s ease-in-out 0s;
}

.container-d.is-active.end {
  transition: opacity 1s ease-in-out 1s;
}

.container-d .replay {
  cursor: pointer;
}
