body {
  text-align: center;
}

.title {
  font-size: 80px;
  line-height: 0.9;
  margin: 30px 0 0 0;
  padding: 0 30px;
}

.intro-img {
  display: block;
  margin: 0 auto;
  width: 88%;
}

.author {
  font-size: 40px;
  line-height: 1;
  margin: 20px 0 10px 0;
  padding: 0 30px;
}

.illustrator {
  font-size: 24px;
  margin: 0;
}

.illustrator a {
  color: inherit;
}

.sidekick {
  display: block;
  margin: 90px auto 0 auto;
  width: 150px;
}

.hero {
  display: block;
  width: 100%;
}

.copy {
  display: block;
  font-size: 28px;
  margin: 0;
  padding: 0 50px 23px 50px;
  width: 100%;
}

.copy span {
  white-space: nowrap;
}

.lang-buttons {
  display: inline-block;
  padding: 12px 0 90px 0;
  position: relative;
}

.lang-buttons::after {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  height: 1px;
  margin-left: -30px;
  left: 50%;
  position: absolute;
  width: 60px;
  top: 0;
}

.lang-buttons span {
  display: inline-block;
  font-size: 20px;
  opacity: 0.4;
  transition: opacity 500ms ease-in-out;
  padding: 8px;
  margin: 0 1px;
}

.lang-buttons span.is-active {
  opacity: 1;
}

.end {
  display: flex;
  flex-direction: column;
}

.end-text {
  font-size: 70px;
  line-height: 1;
  padding: 0 30px;
  margin: 100px 0 0 0;
}

.end-img {
  display: block;
  margin: 30px auto 0 auto;
  width: 200px;
}

.replay {
  display: flex;
  flex-direction: column;
  font-size: 32px;
  margin: 40px 0 200px 0;
}

.replay img {
  height: 25px;
  margin-top: 5px;
}
